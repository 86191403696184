<template>
   <b-container>
      <h1 class="mb-4">Questionnaires</h1>

      <b-card class="mb-3">
         <h3 class="text-secondary mb-3">Company Questionnaires</h3>
         <b-table
            :fields="[{key: 'title', sortable: true}, {key: 'years'}, {key: 'actions', label: ''}]"
            sort-by="title"
            :items="companyQuestionnaires"
            id="table-company-questionnaires"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(years)="data"> {{ data.value.lower }}-{{ data.value.upper }} </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button size="sm" variant="danger" @click="onDelete(data.item)">
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-card>
         <h3 class="text-secondary mb-3">Project Questionnaires</h3>
         <b-table
            :fields="projectQuestionnaireFields"
            sort-by="title"
            :items="projectQuestionnaires"
            id="table-project-questionnaires"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(years)="data"> {{ data.value.lower }}-{{ data.value.upper }} </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-delete-${data.item.id}`"
                     size="sm"
                     variant="danger"
                     @click="onDelete(data.item)"
                  >
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         :title="`Delete ${selectedQuestionnaireType} Questionnaire`"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
         @hidden="selectedItem = null"
      >
         <p>
            Are you sure you want to delete
            {{ selectedQuestionnaireType.toLowerCase() }} questionnaire
            <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         relatedData: {},
         selectedItem: null,
         projectQuestionnaireFields: [
            {key: 'title', sortable: true},
            {key: 'years'},
            {
               key: 'projectId',
               label: 'Project',
               formatter: (value) => this.projectMap[value].name,
               sortable: true,
               sortByFormatted: true,
            },
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         companyQuestionnaires: 'questionnaire/companyQuestionnaires',
         projectQuestionnaires: 'questionnaire/projectQuestionnaires',
         projectMap: 'projects/projectMap',
      }),

      companyId() {
         return this.$route.params.id;
      },

      /** An array of strings describing data that will also be deleted when the selected questionnaire is deleted */
      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }

         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.name;
         let relatedStrings = [rootName];

         let answeredCount = 0;
         let unansweredCount = 0;
         rootObj.related.forEach((key) => {
            const obj = this.relatedData[key];
            if (obj.type === 'Question_Questionnaire') {
               if (obj.name === 'Answered Questions') {
                  answeredCount += obj.count;
               } else {
                  unansweredCount += obj.count;
               }
            }
         });

         if (answeredCount > 0 || unansweredCount > 0) {
            relatedStrings.push(
               `${answeredCount + unansweredCount} questions with ${answeredCount} answers`
            );
         }

         return relatedStrings;
      },

      /** The type of questionnaire that's currently selected for deletion */
      selectedQuestionnaireType() {
         if (this.selectedItem === null) {
            return '';
         }
         return this.selectedItem.projectId !== null ? 'Project' : 'Company';
      },
   },

   methods: {
      async onDelete(questionnaire) {
         const relatedData = await this.$store.dispatch('questionnaire/deleteQuestionnaire', {
            questionnaireId: questionnaire.id,
         });
         this.relatedData = relatedData;
         this.selectedItem = questionnaire;
         this.$bvModal.show('modal-confirm-delete');
      },

      async confirmDelete() {
         await this.blockingRequest('questionnaire/deleteQuestionnaire', {
            questionnaireId: this.selectedItem.id,
            force: true,
         });
      },
   },

   async created() {
      const requests = [];

      requests.push(
         this.$store.dispatch('questionnaire/loadQuestionnaires', {
            companyId: this.companyId,
            allStudies: true,
         })
      );

      requests.push(
         this.$store.dispatch('projects/loadProjects', {
            companyId: this.companyId,
         })
      );

      await this.blockUntilAllSettled(requests);
   },
};
</script>
