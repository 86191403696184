<template>
   <b-container>
      <h1 class="mb-4">Projects</h1>

      <b-card>
         <b-table
            :fields="fields"
            sort-by="name"
            :items="projects"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-delete-${data.item.id}`"
                     variant="danger"
                     size="sm"
                     @click="onDelete(data.item)"
                  >
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Project"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
      >
         <p>
            Are you sure you want to delete project <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         relatedData: {},
         fields: [
            {key: 'name', sortable: true},
            {key: 'years.lower', label: 'Start Year', sortable: true},
            {key: 'years.upper', label: 'End Year', sortable: true},
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         projects: 'projects/projects',
      }),

      companyId() {
         return this.$route.params.id;
      },

      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }

         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.name;

         let questionnaireCount = 0;
         let timeSurveyDataCount = 0;
         let validatedTimeSurveys = 0;
         let employeeValidatedTimeSurveys = 0;

         rootObj.related.forEach((key) => {
            const obj = this.relatedData[key];
            if (obj.type === 'Questionnaire') {
               questionnaireCount++;
            } else if (obj.type === 'TimeSurvey_Project') {
               timeSurveyDataCount += obj.count;
            } else if (obj.type === 'TimeSurveyValidated_Project') {
               validatedTimeSurveys += obj.count;
            } else if (obj.type === 'TimeSurveyEmployeeValidated_Project') {
               employeeValidatedTimeSurveys += obj.count;
            }
         });

         let relatedStrings = [rootName];
         if (questionnaireCount > 0) {
            relatedStrings.push(`${questionnaireCount} project questionnaires`);
         }
         if (timeSurveyDataCount > 0) {
            relatedStrings.push(`${timeSurveyDataCount} records of qualified wages data`);
         }
         if (validatedTimeSurveys > 0) {
            relatedStrings.push(`${validatedTimeSurveys} time survey validations`);
         }
         if (employeeValidatedTimeSurveys > 0) {
            relatedStrings.push(`${employeeValidatedTimeSurveys} employee time survey validations`);
         }

         if (relatedStrings.length < 2) {
            relatedStrings.push('No related data');
         }
         return relatedStrings;
      },
   },

   methods: {
      async onDelete(project) {
         const relatedData = await this.$store.dispatch('projects/deleteProject', {
            projectId: project.id,
         });
         this.relatedData = relatedData;
         this.$bvModal.show('modal-confirm-delete');
      },

      async confirmDelete() {
         const projectId = this.relatedData[this.relatedData._root].id;
         await this.blockingRequest('projects/deleteProject', {
            projectId,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('projects/loadProjects', {
         companyId: this.companyId,
      });
   },
};
</script>
