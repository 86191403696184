<template>
   <b-container>
      <h1 class="mb-3">Industries</h1>

      <b-row>
         <b-col cols="3">
            <b-list-group>
               <b-list-group-item
                  id="btn-add-industry"
                  variant="primary"
                  button
                  @click="startCreateIndustry"
               >
                  <b-icon-plus font-scale="1.2" /> <b>Add New Industry</b>
               </b-list-group-item>
               <b-list-group-item
                  :id="`btn-industry-${indIdx + 1}`"
                  v-for="(lIndustry, indIdx) in industries"
                  :key="lIndustry.id"
                  button
                  :variant="industryIsActive(lIndustry.id) ? 'secondary' : null"
                  @click="selectIndustry(lIndustry.id)"
               >
                  {{ lIndustry.name }}
                  <span v-if="lIndustry.is_default" class="text-primary">*</span>
               </b-list-group-item>
            </b-list-group>
         </b-col>

         <b-col cols="9">
            <b-alert :show="editIndustry === null" variant="primary">
               Select an existing industry to edit it, or add a new industry.
            </b-alert>

            <b-card v-if="editIndustry">
               <h2>Edit Industry</h2>

               <b-alert :show="editIndustry.is_default" variant="primary">
                  This is the default industry
               </b-alert>

               <b-form-group label="Name">
                  <b-form-input
                     id="input-edit-industry-name"
                     :value="editIndustry.name"
                     @input="(val) => updateEditData('name', val)"
                  ></b-form-input>
               </b-form-group>

               <IndustrySection ident="prompts" title="User Prompts">
                  <b-form-group label="Supplies Prompt">
                     <b-form-textarea
                        id="input-edit-industry-supplies-prompt"
                        :value="editIndustry.prompts.supplies"
                        @input="(val) => updateEditData('prompts.supplies', val)"
                        rows="4"
                     ></b-form-textarea>
                  </b-form-group>

                  <b-form-group label="Uploads Prompt">
                     <b-form-textarea
                        id="input-edit-industry-uploads-prompt"
                        :value="editIndustry.prompts.uploads"
                        @input="(val) => updateEditData('prompts.uploads', val)"
                        rows="4"
                     ></b-form-textarea>
                  </b-form-group>
               </IndustrySection>

               <IndustrySection ident="default-activities" title="Default Qualified Activities">
                  <b-list-group>
                     <b-list-group-item
                        id="btn-add-activity"
                        variant="primary"
                        button
                        @click="startCreateActivity"
                     >
                        <b-icon-plus font-scale="1.2" /> <b>Add New Qualified Activity</b>
                     </b-list-group-item>

                     <b-list-group-item
                        v-for="([name, description], idx) in sortedActivities"
                        :key="idx + 1"
                     >
                        <div class="d-flex align-items-start justify-content-between mb-1">
                           <b :id="`activity-name-${idx + 1}`">{{ name }}</b>
                           <div class="d-flex align-items-center ml-2">
                              <button
                                 :id="`btn-edit-${idx + 1}`"
                                 class="icon-btn icon-btn-primary"
                                 type="button"
                                 @click="startEditActivity(name)"
                                 v-b-tooltip="'Edit this qualified activity'"
                              >
                                 <b-icon-pencil></b-icon-pencil>
                              </button>

                              <button
                                 :id="`btn-delete-${idx + 1}`"
                                 class="icon-btn icon-btn-danger ml-2"
                                 type="button"
                                 @click="onDeleteActivity(name)"
                                 v-b-tooltip="'Delete this qualified activity'"
                              >
                                 <b-icon-trash></b-icon-trash>
                              </button>
                           </div>
                        </div>

                        <p :id="`activity-description-${idx + 1}`">{{ description }}</p>
                     </b-list-group-item>
                  </b-list-group>
               </IndustrySection>

               <div class="d-flex align-items-center justify-content-end">
                  <b-button
                     id="btn-save-industry"
                     variant="primary"
                     @click="updateIndustry"
                     :disabled="$v.editIndustry.$invalid || !editDataDirty"
                  >
                     Save
                  </b-button>
               </div>
            </b-card>
         </b-col>
      </b-row>

      <b-modal
         id="modal-create-industry"
         title="Add New Industry"
         centered
         ok-title="Submit"
         @ok="createIndustry"
         :ok-disabled="$v.newIndustry.$invalid"
      >
         <b-form-group label="Name">
            <b-form-input id="input-new-industry-name" v-model="newIndustry.name"></b-form-input>
         </b-form-group>
      </b-modal>

      <b-modal
         id="modal-new-activity"
         title="New Qualified Activity"
         centered
         ok-title="Submit"
         @ok="addActivity"
         :ok-disabled="$v.newActivity.$invalid"
         size="lg"
      >
         <b-form-group
            label="Name"
            :state="newActivity.name && $v.newActivity.name.$invalid ? false : null"
            invalid-feedback="A qualified activity with this name already exists"
         >
            <b-form-input id="input-activity-name" v-model="newActivity.name"></b-form-input>
         </b-form-group>
         <b-form-group label="Description">
            <b-form-textarea
               id="input-activity-description"
               v-model="newActivity.description"
               rows="4"
            ></b-form-textarea>
         </b-form-group>
      </b-modal>

      <b-modal
         id="modal-edit-activity"
         title="Edit Qualified Activity"
         centered
         ok-title="Submit"
         @ok="saveActivity"
         :ok-disabled="$v.updatedActivity.$invalid"
         size="lg"
      >
         <b-form-group
            label="Name"
            :state="updatedActivity.name && $v.updatedActivity.name.$invalid ? false : null"
            invalid-feedback="A qualified activity with this name already exists"
         >
            <b-form-input id="input-activity-name" v-model="updatedActivity.name"></b-form-input>
         </b-form-group>
         <b-form-group label="Description">
            <b-form-textarea
               id="input-activity-description"
               v-model="updatedActivity.description"
               rows="4"
            ></b-form-textarea>
         </b-form-group>
      </b-modal>

      <b-modal
         id="modal-delete-activity"
         title="Delete Qualified Activity"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDeleteActivity"
      >
         <p>
            Are you sure you want to delete qualified activity <b>{{ deleteActivity }}</b
            >?
         </p>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';

import IndustrySection from './widgets/IndustrySection';
import {sortBy} from '../../helpers/utils';

export default {
   components: {
      IndustrySection,
   },

   data() {
      return {
         deleteActivity: null,
         newIndustry: {name: null, activities: {}, prompts: {supplies: null, uploads: null}},
         newActivity: {name: null, description: null},
         updatedActivity: {_originalName: null, name: null, description: null},
      };
   },

   computed: {
      ...mapGetters({
         industries: 'industries/industries',
         editIndustry: 'industries/editIndustry',
         editDataDirty: 'industries/editDataDirty',
         activityMap: 'industries/editIndustryActivities',
      }),

      sortedActivities() {
         return Object.entries(this.activityMap).sort(sortBy(0, (x) => x.toUpperCase()));
      },
   },

   methods: {
      /**
       * Ask the user to save before proceeding with an action. Return value indicates
       * whether or not the action should be proceeded with.
       */
      async checkSave() {
         const doSave = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to proceed? Any unsaved changes will be lost.',
            {
               title: 'Save before proceeding?',
               centered: true,
               okTitle: 'Save',
               cancelTitle: 'Proceed without saving',
            }
         );
         if (doSave) {
            await this.updateIndustry();
            return true;
         } else if (doSave === false) {
            return true;
         } else {
            return false;
         }
      },

      async confirmDeleteActivity() {
         this.$store.commit('industries/deleteActivity', {name: this.deleteActivity});
      },

      async onDeleteActivity(activity) {
         this.deleteActivity = activity;
         this.$bvModal.show('modal-delete-activity');
      },

      /** Select and industry for editing */
      async selectIndustry(industryId) {
         if (this.editDataDirty) {
            const proceed = await this.checkSave();
            if (!proceed) {
               return;
            }
         }
         this.$store.commit('industries/loadIndustryForEdit', {industryId});
      },

      /** Is the given industry active for editing? */
      industryIsActive(industryId) {
         return this.editIndustry?.id === industryId;
      },

      /** Display the create activity modal */
      startCreateActivity() {
         this.newActivity = {name: null, description: null};
         this.$bvModal.show('modal-new-activity');
      },

      /** Display the create industry modal */
      startCreateIndustry() {
         this.newIndustry = {name: null, activities: {}, prompts: {supplies: null, uploads: null}};
         this.$bvModal.show('modal-create-industry');
      },

      /** Display the edit activity modal */
      async startEditActivity(activityName) {
         this.updatedActivity = {
            _originalName: activityName,
            name: activityName,
            description: this.activityMap[activityName],
         };
         this.$bvModal.show('modal-edit-activity');
      },

      /** Update a value in the edit industry data */
      updateEditData(field, value) {
         this.$store.commit('industries/updateEditData', {field, value});
      },

      /** Store the new activity */
      async addActivity() {
         this.$store.commit('industries/setActivity', this.newActivity);
      },

      async createIndustry() {
         try {
            await this.blockingRequest('industries/createIndustry', this.newIndustry);
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save industry',
               seconds: 5,
            });
         }
      },

      async saveActivity() {
         this.$store.commit('industries/deleteActivity', {
            name: this.updatedActivity._originalName,
         });
         this.$store.commit('industries/setActivity', this.updatedActivity);
      },

      async updateIndustry() {
         try {
            await this.blockingRequest('industries/updateIndustry');
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save industry',
               seconds: 5,
            });
         }
      },
   },

   async mounted() {
      await this.blockingRequest('industries/loadIndustries');
      this.$store.commit('industries/clearEditIndustry');
   },

   /** Before leaving the route, check for any unsaved changes */
   async beforeRouteLeave(to, from, next) {
      if (this.editDataDirty) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'You have unsaved changes. Would you like to stay on this page to save your work, or proceed and discard any unsaved changes?',
            {
               id: 'modal-proceed',
               centered: true,
               title: 'Proceed without saving?',
               okTitle: 'Proceed without saving',
               cancelTitle: 'Stay',
            }
         );
         if (!proceed) {
            // Cancel the navigation
            return next(false);
         }
      }
      next();
   },

   validations: {
      newActivity: {
         name: {
            required,
            isUnique(name) {
               return !this.sortedActivities.some((entity) => {
                  return entity[0] === name;
               });
            },
         },
      },
      newIndustry: {
         name: {
            required,
         },
      },
      editIndustry: {
         name: {
            required,
         },
      },
      updatedActivity: {
         name: {
            required,
            isUnique(name, activity) {
               if (name === activity._originalName) {
                  return true;
               }
               return !this.sortedActivities.some((entity) => {
                  return entity[0] === name;
               });
            },
         },
      },
   },
};
</script>
