<template>
   <div>
      <div
         :id="`toggle-${ident}`"
         class="section-headers d-flex align-items-center justify-content-between mb-3 mt-4"
         v-b-toggle="`collapse-${ident}`"
      >
         <h3 class="mb-0 pb-2 text-secondary">{{ title }}</h3>
         <custom-icon :icon="collapseIcon" class="text-secondary" />
      </div>

      <b-collapse :id="`collapse-${ident}`" v-model="expanded" class="mb-3" @input="toggleVisible">
         <slot />
      </b-collapse>
   </div>
</template>

<script>
export default {
   props: {
      ident: {
         type: String,
      },

      value: {
         type: Boolean,
         default: false,
      },

      title: {
         type: String,
         default: '',
      },
   },

   data() {
      return {
         expanded: this.value,
      };
   },

   computed: {
      collapseIcon() {
         return this.expanded ? 'icon-chevron-up' : 'icon-chevron-down';
      },
   },

   methods: {
      toggleVisible(value) {
         this.$emit('input', value);
      },
   },
};
</script>
<style lang="scss" scoped>
.section-headers {
   border-bottom: 1px solid #ccc;
}
</style>
