<template>
   <b-container v-if="loaded">
      <div class="d-flex align-items-center justify-content-between mb-4">
         <h1 class="mb-0">{{ company.companyName }}</h1>
         <div class="d-flex align-items-center">
            <b-button
               id="btn-company-staff-view"
               class="mr-2"
               :to="{name: 'company-detail', params: {id: companyId}}"
            >
               Staff View
            </b-button>
            <b-button id="btn-delete-company" variant="danger" @click="onDelete">
               Delete Company
            </b-button>
         </div>
      </div>

      <b-card class="mb-5">
         <b-table
            :fields="[
               {key: 'entity', sortable: true},
               {key: 'actions', label: ''},
            ]"
            :items="entities"
            class="mb-0"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     v-if="data.item.staffRoute"
                     :id="`btn-${data.item.key}-staff-view`"
                     :to="data.item.staffRoute"
                     size="sm"
                     class="mr-2"
                  >
                     Staff View
                  </b-button>
                  <b-button
                     v-if="data.item.adminRoute"
                     :id="`btn-${data.item.key}-manage`"
                     :to="data.item.adminRoute"
                     size="sm"
                  >
                     Manage
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-card>
         <h2>Copy this company</h2>
         <div v-if="pendingCopies.length === 0">
            <p>
               Create copies of this company with its current study configuration. Enter the names
               of the newly created companies, one name per line.
            </p>

            <b-form-textarea
               id="textarea-copy-company-names"
               class="mb-3"
               rows="5"
               v-model="copyCompanyConfig"
            ></b-form-textarea>

            <div class="d-flex align-items-center justify-content-end">
               <b-button
                  id="btn-copy-company-submit"
                  variant="primary"
                  @click="copyCompany"
                  :disabled="copyCompanyNames.length === 0"
               >
                  Submit
               </b-button>
            </div>
         </div>
         <div v-else>
            <b-table
               id="table-copy-company-list"
               :fields="copyProgressFields"
               :items="pendingCopies"
            >
               <template #cell(name)="data">
                  <b>{{ data.value }}</b>
               </template>
               <template #cell(actions)="data">
                  <template v-if="data.item.status === CopyStatus.PENDING"> Pending... </template>
                  <div
                     v-else-if="data.item.status === CopyStatus.IN_PROGRESS"
                     class="d-flex align-items-center"
                  >
                     <b-spinner variant="primary" small class="mr-2"></b-spinner> Copying...
                  </div>
                  <div
                     v-else-if="data.item.status === CopyStatus.COMPLETE"
                     class="cell-w-buttons d-flex align-items-center justify-content-end"
                  >
                     <b-button
                        :id="`btn-staff-company-${data.index}`"
                        size="sm"
                        class="mr-2"
                        :to="{name: 'company-detail', params: {id: data.item.company.id}}"
                     >
                        Staff View
                     </b-button>
                     <b-button
                        :id="`btn-manage-company-${data.index}`"
                        size="sm"
                        :to="{name: 'admin-company', params: {id: data.item.company.id}}"
                     >
                        Manage
                     </b-button>
                  </div>
                  <div
                     v-else-if="data.item.status === CopyStatus.FAILED"
                     class="cell-w-buttons d-flex align-items-center justify-content-end"
                  >
                     <b-button variant="danger" @click="showError(data.item.error)">Error</b-button>
                  </div>
               </template>
            </b-table>

            <div class="d-flex align-items-center justify-content-end">
               <b-button id="btn-create-more-copies" variant="primary" @click="resetCopyConfig">
                  Create more copies
               </b-button>
            </div>
         </div>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Company"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
         size="lg"
      >
         <p>
            Are you sure you want to delete company <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {CopyStatus} from '@/store/modules/copy-company';

export default {
   data() {
      return {
         CopyStatus,
         relatedData: {},
         loaded: false,
         copyCompanyConfig: '',
         copyProgressFields: [{key: 'name'}, {key: 'actions', label: ''}],
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         pendingCopies: 'copyCompany/pendingCopies',
      }),

      companyId() {
         return this.$route.params.id;
      },

      /** A list of data types associated with a company */
      entities() {
         return [
            {
               key: 'employees',
               entity: 'Employees',
               adminRoute: {name: 'admin-employees', params: {id: this.companyId}},
               staffRoute: {name: 'employees-configure', params: {id: this.companyId}},
            },
            {
               key: 'activities',
               entity: 'Qualified Activities',
               adminRoute: {name: 'admin-activities', params: {id: this.companyId}},
               staffRoute: {name: 'activities-configure', params: {id: this.companyId}},
            },
            {
               key: 'projects',
               entity: 'Projects',
               adminRoute: {name: 'admin-projects', params: {id: this.companyId}},
               staffRoute: {name: 'projects-configure', params: {id: this.companyId}},
            },
            {
               key: 'questionnaires',
               entity: 'Questionnaires',
               adminRoute: {name: 'admin-questionnaires', params: {id: this.companyId}},
            },
            {
               key: 'time-survey',
               entity: 'Time Surveys',
               adminRoute: {name: 'admin-timesurvey', params: {id: this.companyId}},
            },
            {
               key: 'upload-categories',
               entity: 'Document Categories',
               adminRoute: {name: 'admin-document-categories', params: {id: this.companyId}},
               staffRoute: {name: 'configure-uploads', params: {id: this.companyId}},
            },
         ];
      },

      /** An array of strings representing data related to this company */
      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }
         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.name;
         const relatedStrings = [rootName];

         let aggrigates = {
            tsRecords: 0,
            tsYears: 0,
            tsValidated: 0,
            employees: 0,
            projects: 0,
            companyQuestionnaires: 0,
            projectQuestionnaires: 0,
            answeredQuestions: 0,
            unansweredQuestions: 0,
            uploadCategories: 0,
            files: 0,
         };
         this.aggrigateRelatedData(this.relatedData._root, aggrigates);

         // Time survey records
         if (aggrigates.tsRecords > 0) {
            relatedStrings.push(
               `${aggrigates.tsRecords} records of employee R&D time - ${aggrigates.employees} employees over the course of ${aggrigates.tsYears} years & ${aggrigates.projects} projects`
            );
         }
         // Questionnaires
         if (aggrigates.companyQuestionnaires > 0 || aggrigates.projectQuestionnaires > 0) {
            let qString = '';
            if (aggrigates.companyQuestionnaires > 0) {
               qString += `${aggrigates.companyQuestionnaires} company questionnaires `;
            }
            if (aggrigates.projectQuestionnaires > 0) {
               if (aggrigates.companyQuestionnaires > 0) {
                  qString += '& ';
               }
               qString += `${aggrigates.projectQuestionnaires} project questionnaires `;
            }
            qString += `- ${
               aggrigates.answeredQuestions + aggrigates.unansweredQuestions
            } questions with ${aggrigates.answeredQuestions} submitted answers`;
            relatedStrings.push(qString);
         }
         // Document uploads
         if (aggrigates.uploadCategories > 0) {
            relatedStrings.push(
               `${aggrigates.files} uploaded documents in ${aggrigates.uploadCategories} upload categories`
            );
         }
         // Employees
         if (aggrigates.employees > 0) {
            relatedStrings.push(`${aggrigates.employees} total employee names`);
         }
         // Validated Time surveys
         if (aggrigates.tsValidated > 0) {
            relatedStrings.push(`${aggrigates.tsValidated} time survey validations`);
         }

         if (relatedStrings.length < 2) {
            relatedStrings.push('No related data');
         }

         return relatedStrings;
      },

      /** Parse the textarea for company names, one per line. */
      copyCompanyNames() {
         const names = [];
         this.copyCompanyConfig.split('\n').forEach((name) => {
            const normalizedName = name.trim();
            if (normalizedName.length > 0) {
               names.push(normalizedName);
            }
         });
         return names;
      },
   },

   methods: {
      /** Aggrigates the data in the relatedData object returned when deleting */
      aggrigateRelatedData(key, aggrigates) {
         const obj = this.relatedData[key];

         switch (obj.type) {
            case 'TimeSurvey_Project':
               // Count time survey records
               aggrigates.tsRecords += obj.count;
               break;
            case 'Employee_Company':
               // Count employees
               aggrigates.employees += obj.count;
               break;
            case 'Project':
               // Count projects
               aggrigates.projects++;

               // Count project questionnaires
               obj.related.forEach((rKey) => {
                  if (this.relatedData[rKey].type === 'Questionnaire') {
                     aggrigates.projectQuestionnaires++;
                  }
               });
               break;
            case 'Company':
               // Count company questionnaires
               obj.related.forEach((rKey) => {
                  if (this.relatedData[rKey].type === 'Questionnaire') {
                     aggrigates.companyQuestionnaires++;
                  }
               });
               break;
            case 'UploadCategory':
               // Count upload categories
               aggrigates.uploadCategories++;
               break;
            case 'UploadFile_UploadCategory':
               // Count uploaded files
               aggrigates.files += obj.count;
               break;
            case 'Question_Questionnaire':
               if (obj.name === 'Answered Questions') {
                  aggrigates.answeredQuestions += obj.count;
               } else {
                  aggrigates.unansweredQuestions += obj.count;
               }
               break;
            case 'TimeSurvey_Company':
               // Count time survey years
               aggrigates.tsYears += obj.count;
               break;
            case 'TimeSurveyValidated':
               // Count validated time surveys
               aggrigates.tsValidated++;
               break;
            default:
               break;
         }

         obj.related.forEach((rKey) => this.aggrigateRelatedData(rKey, aggrigates));

         return aggrigates;
      },

      /**
       * Makes the initial delete request without the force flag, meaning
       * data is not actually deleted here. Instead, the server returns a relatedData
       * object. Once we have that, we can ask the user "Are you sure?"
       */
      async onDelete() {
         const relatedData = await this.$store.dispatch('companies/deleteCompany', {
            companyId: this.companyId,
         });
         this.relatedData = relatedData;
         this.$bvModal.show('modal-confirm-delete');
      },

      /** Actually do the deletion */
      async confirmDelete() {
         await this.blockingRequest('companies/deleteCompany', {
            companyId: this.companyId,
            force: true,
         });
         this.$router.push({name: 'Home'});
      },

      /** Create copies of the current company for each name entered in the textarea */
      copyCompany() {
         this.$store.dispatch('copyCompany/copyCompanies', {
            companyId: this.companyId,
            names: this.copyCompanyNames,
         });
      },

      /** Clear the copy company UI */
      resetCopyConfig() {
         this.$store.commit('copyCompany/clear');
         this.copyCompanyConfig = '';
      },

      /** Display an error message in a modal */
      showError(msg) {
         this.$bvModal.msgBoxOk(msg, {
            title: 'Error',
            centered: true,
         });
      },
   },

   async created() {
      this.$store.commit('copyCompany/clear');
      await this.blockingRequest('companies/loadCompany', {companyId: this.companyId});
      this.loaded = true;
   },
};
</script>
