<template>
   <b-container>
      <h1 class="mb-4">Employees</h1>

      <b-card>
         <b-table
            :fields="fields"
            sort-by="fullname"
            :items="employees"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-delete-${data.item.id}`"
                     variant="danger"
                     size="sm"
                     @click="onDelete(data.item)"
                  >
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Employee"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
      >
         <p>
            Are you sure you want to delete employee <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         relatedData: {},
      };
   },

   computed: {
      ...mapGetters({
         employees: 'employees/employees',
      }),

      companyId() {
         return this.$route.params.id;
      },

      fields() {
         return [
            {key: 'fullname', label: 'Name', sortable: true, stickyColumn: true},
            {key: 'year', sortable: true},
            {key: 'ident', label: 'Employee ID', sortable: true},
            {key: 'location', sortable: true},
            {key: 'title', sortable: true},
            {key: 'department', sortable: true},
            {key: 'subsidiary', sortable: true},
            {key: 'actions', label: ''},
         ];
      },

      /** An array of strings describing related data to be deleted alongside the selected employee. */
      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }

         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.name;
         const relatedStrings = [rootName];

         let timeSurveyDataCount = 0;
         rootObj.related.forEach((key) => {
            const obj = this.relatedData[key];
            if (obj.type === 'TimeSurveyBase_Employee') {
               timeSurveyDataCount += obj.count;
            }
         });

         if (timeSurveyDataCount > 0) {
            relatedStrings.push(
               `${timeSurveyDataCount} employee time records. This includes R&D project percentages and qualified R&D activity contributions.`
            );
         }
         if (relatedStrings.length < 2) {
            relatedStrings.push('No related data');
         }

         return relatedStrings;
      },
   },

   methods: {
      /**
       * Handle the initial delete request (without the force flag). Display the confirmation modal.
       * @param {Object} employee - The employee to delete
       */
      async onDelete(employee) {
         const relatedData = await this.$store.dispatch('employees/deleteEmployee', {
            employeeId: employee.id,
         });
         this.relatedData = relatedData;
         this.$bvModal.show('modal-confirm-delete');
      },

      /** Handle the delete confirmation. Send the delete request with the force flag. */
      async confirmDelete() {
         const employeeId = this.relatedData[this.relatedData._root].id;
         await this.blockingRequest('employees/deleteEmployee', {
            employeeId,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('employees/loadEmployees', {
         companyId: this.companyId,
         allStudies: true,
      });
   },
};
</script>
