<template>
   <b-container>
      <h1 class="mb-4">Time Surveys</h1>

      <b-card>
         <p>
            Note: Time surveys only appear in this list if data has been entered. Deleting a time
            survey only clears the data from that time survey.
         </p>

         <b-table
            :fields="[
               {key: 'year', sortable: true},
               {key: 'actions', label: ''},
            ]"
            sort-by="year"
            :items="years"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-delete-${data.item.year}`"
                     @click="onDelete(data.item.year)"
                     variant="danger"
                     size="sm"
                  >
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Time Survey"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
      >
         <p>
            Are you sure you want to delete time survey <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         relatedData: {},
      };
   },

   computed: {
      ...mapGetters({
         _years: 'timesurvey/years',
      }),

      companyId() {
         return this.$route.params.id;
      },

      years() {
         return this._years.DATA.map((year) => ({year}));
      },

      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }

         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.id.split(':')[2];
         let relatedStrings = [rootName];

         let validated = false;
         let assignments = 0;
         rootObj.related.forEach((key) => {
            const obj = this.relatedData[key];
            if (obj.type === 'TimeSurveyValidated') {
               validated = obj.count > 0;
            } else if (obj.type === 'TimeSurveyAssignment') {
               assignments += obj.count;
            }
         });

         relatedStrings.push('All data entered for this time survey');
         if (validated) {
            relatedStrings.push('Validation for this time survey');
         }
         if (assignments > 0) {
            relatedStrings.push(`${assignments} employee assignments`);
         }

         if (relatedStrings.length < 2) {
            relatedStrings.push('No related data');
         }
         return relatedStrings;
      },
   },

   methods: {
      async onDelete(year) {
         const relatedData = await this.blockingRequest('timesurvey/deleteTimeSurvey', {
            companyId: this.companyId,
            year,
         });
         this.relatedData = relatedData;
         this.$bvModal.show('modal-confirm-delete');
      },

      async confirmDelete() {
         const year = this.relatedData[this.relatedData._root].id.split(':')[2];
         await this.blockingRequest('timesurvey/deleteTimeSurvey', {
            companyId: this.companyId,
            year,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('timesurvey/loadYears', {
         companyId: this.companyId,
         allStudies: true,
      });
   },
};
</script>
